$bg:#003E6B;
$grey:#71717A;
$btn:#009AD9;
$title:#151C55;
$txtcolor: #38454E;
$txttwo: #272B36;
$font-family: 'Ubuntu', sans-serif;
$placeholder: #8692A6;
$pending: #E34C4C;
$active: #F5A021;
$complete: #56BD5B;
$control-border-color: #DFE6EE;
$black: #000;
$primary: #1A8DCE;
$breadcrumb: #F7F8FC;
$tab: #464B4E;
$info: #3C464D;

