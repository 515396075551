@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import '/src/assets/css/globalVariables.scss';
html,body{
    height: 100%; 
}
@font-face {
  font-family: 'FontAwesome';
  src: url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
}
body{
    color: $bg;;
    font-family: $font-family;
    font-size: 0.875rem;
    font-weight: 500;
}
label{font-weight: 300;font-family: $font-family;
    font-size: 16px;
    line-height: 18px;margin-bottom: 10px;
    color: #38454E;}
.form-control{width: 810px;max-width: 100%;font-family: $font-family;
   background-color: #fff;
   min-height: 64px;
    border-radius: 10px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3C464D;
    &:focus{
        box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
        border-color: $btn;
    }
    &::placeholder{
       font-size: 0.875rem;
        color: $placeholder;
        font-weight: 400;
    }
}
.input-group-append{position: absolute;right: 20px;top:24%; z-index: 3;
    .input-group-text{background-color: transparent;border: none;text-decoration: none;font-size: 14px;color: #000;font-weight: 400;font-family: $font-family;}
}
.sub-btn{color: #fff;font-size: 16px;overflow: hidden;background-color: $btn;font-weight: 700;
    line-height: 18px;margin-top: 40px;margin-bottom: 47px;font-family: $font-family;max-height: 64px;transition: all 0.5s;position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $btn;
        transition: all 0.3s;
      }
      &:hover::before, &:focus::before {
        opacity: 0 ;
        transform: scale(0.5,0.5);
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;background-color: transparent;
        border: 1px solid $btn;
        transform: scale(1.2,1.2);
      }
      &:hover::after, &:focus::after {
        opacity: 1;
        transform: scale(1,1);
      }
      &:hover{background-color: transparent;color: $btn;}
    }
    .frgt-text{color: $txtcolor;font-weight: 500;
        font-size: 16px;transition: all 0.3s;
        line-height: 18px;font-family: $font-family;text-decoration: none;
        &:hover{color: $btn;}
    }
    .add-btn{background-color: $btn;padding: 1rem;border:2px solid $btn;font-size: 12px;font-weight: 700;color:#fff;text-transform:uppercase; padding: 12px 16px;
      display: flex;align-items: center;
      max-height: 42px;width:160px;justify-content:center;
      i{margin-right: 5px;   }
      &:hover{background-color: transparent;color: $btn;}
  }
  .clear-btn{background-color: transparent;padding: 1rem;border:2px solid $grey;font-size: 14px;font-weight: 700;color:$grey;text-transform:uppercase; padding: 12px 16px;
    display: flex;align-items: center;
    max-height: 42px;
    i{margin-right: 5px;   }
    &:hover{background-color: $grey;color: #fff;}
}
  ::ng-deep {
// Table
.status{
  display: inline-block;
  padding: .1rem .65rem;
  line-height: 1.25;
  
}
.table{
  
  thead{
    
      tr{
          th{
             
              font-weight: 500;
              color: $txtcolor;font-size: 12px;;
              text-transform: uppercase;
              padding:  0.85rem;font-weight: 500;
              border-bottom: 1px solid #DBDFEA;
              border-top:  1px solid #DBDFEA;
              padding: 1.2rem 18px !important;
          }
      }
  }
  tbody{
      tr{
        border-bottom: 1px solid #DBDFEA;
          td{
              font-size: 14px;font-weight: 400;
              padding: 1rem 0.85rem;border-top: none;
              vertical-align: middle;color:$txtcolor;
              text-align: left;
              .status{
                  border-radius: 2.1875rem;
                  &.success{
                      background-color: $complete;
                      color: #fff;
                  }
                  &.warning{
                      background-color: $active;
                      color: #fff;
                  }
                  &.danger{
                      background-color: $pending;
                      color: #fff;
                  }
              }
              .btn-wrapper{
                  display: flex;
                  justify-content: center;
                  grid-gap: .5rem;
                  .btn{
                     
                      width: 2.5rem;
                      i{
                          margin: 0;
                          
                      }
                  }
              }
              span{max-width: 200px;
                display: block;
                min-width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.ep-report-name-width{
                  max-width: 500px !important;
                }
              }
             
          }
      }
  }
  &.dataTable{
      // border-radius: 1.25rem;
      overflow: hidden;
      // border-bottom: none !important;
      border: 1px solid #DBDFEA !important;
      

  }
  
}

.dataTables_wrapper{
  padding-top: 60px;
  .dataTables_filter {
    position: absolute;right: 178px;top: 4px;
      // display: none;
    color: #000;
    label{
      font-size: 0;
      margin-bottom: 1rem;
      position: relative;
      &:focus-within{
        &::before{
          display: inline
        }
      }

      &::before{
        position: absolute;
        font-family: 'FontAwesome';
        font-weight: 900;
        content: '\f002';
        color: #8692A6;
        top: 0.55rem;
        left: 0.6rem;
        font-size: 0.95rem;
        }

        input {
          margin-left: 0em;
          background-color: white;
          padding: 0.25rem 0.25rem 0.30rem 1rem;
          width: 355px;
          min-height: 36px;line-height: 30px;max-height: 36px;
          border: 0.06rem solid $control-border-color;
          border-radius: 0.5rem;
          color: $black;
          font-weight: 400;
          font-size: 1rem;
          padding-left: 30px;    overflow: hidden;
          text-overflow: ellipsis;
          &:focus{
            
              box-shadow: 0 0 0 0.06rem $primary;
              border-color: $primary;
              outline: none;
              &::before{display: none;}
          }
          &::placeholder{
              
              font-weight: 400;
          }
         }
    }
  
  }
  .dataTables_length{
     position: absolute;bottom: -10px;
  color: $black;
    label{
      margin-bottom: 0;
      select{
          min-height: 2.75rem;
          border: 0.06rem solid $control-border-color;
          border-radius: 0.5rem;
          color: $black;
          font-weight: 500;
          &:focus{
             
              box-shadow: 0 0 0 0.06rem $primary;
              border-color: $primary;
              outline: none;
          }
          &::placeholder{
              
              font-weight: 400;
          }
      }
    }
  }
  .dataTables_info{
    color: $black;
    padding-top: 0;
    margin-top: 1rem;display: none;
  }
  .dataTables_paginate{
      margin-top: .5rem;    display: flex;
      justify-content: center;
      align-items: center;
      .paginate_button{margin: 0 15px;border:none;
        padding: 0.3125rem 0.625rem;
        border-radius: 2px;
        height: 30px;display: flex;align-items: center;justify-content: center;
        min-width: 30px;
        &.previous{
          padding: 0 12px;border: none !important;
          position: relative;
          background: transparent !important;
          color: $bg !important;
          margin: 0 10px;
          display: flex;
          &:hover{border-color:$bg;
              background: $btn !important;
              color:white !important;}
        }
        &.next{
          padding: 0 12px;border: none !important;
          position: relative;
          background: transparent !important;
          color: $btn !important;
          margin: 0 10px;
          display: flex;
          &:hover{border-color:$btn !important;
              background: $btn !important;
              color:white !important;}
        }
        &:hover,&:active{
          border-color:$btn;
          background: $btn;
          color:white !important;
          box-shadow: none; 
        }
      }
      span{
          display: flex;
        .paginate_button {
          width: 30px;margin-left: 0;
          height: 30px;display: flex;align-items: center;justify-content: center;
          padding: 0.3125rem;border-color:$bg;
          line-height: 22px;color:$bg;
          &.current,&:hover,&:active{
            border-color:$btn;
            background: $btn;
            color:white !important;    
            box-shadow: none; 
            &:hover{
              border-color:$btn;
              background: $btn;
              color:white !important;    
              box-shadow: none; 
            }         
          }
        }
      }
    }
    .first, .last{display: none !important;}
  .top{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: .5rem;
  }
  
  .dataTables_processing{
    min-height: 120px !important;
    align-items: center !important;
    margin-top: 110px !important;
    padding-top: 0px !important;
    transform: translate(-50%, -50%) !important;top:0 !important;
    margin-left: 0 !important;
    .loader-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
}
.table-responsive{overflow-y: hidden !important;}

// Forms
.page-description{
  margin: 0 -30px;padding: 19px 30px;background: #F5F6FA;font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #464B4E;margin-bottom: 25px;
}
.content-wrapper{
  .label{color: $txtcolor;font-weight: 400;margin-bottom: 0.8rem;text-transform: capitalize;}
  .form-group{margin-bottom: 0.575rem;
      
      .form-control{max-height: 42px;padding: 12px 1rem;border-radius: 4px;font-weight:500;color: $bg;min-height:inherit;width:100%;resize:none;text-overflow: ellipsis;
          &:focus{
              background-color: #F7F8FC;
              box-shadow: 0 0 0 0.06rem $primary;
              border-color: $primary;
          }
          &::placeholder{
             font-size: 0.875rem;
              color: $placeholder;
              font-weight: 400;
          }
          @media screen and (max-width: 1536px) {
            max-height: 36px;padding:9px 16px
          }
      }
      .ng-select .ng-select-container .ng-value-container .ng-placeholder{color: $bg;;}
  }
}

// Breadcrumb
.bread-wrap{position: fixed;
  top: 30px;
  z-index: 1030;}
.breadcrumb{margin-bottom: 0;font-size: 14px;
  font-style: normal;font-family: $font-family;font-weight: 300;color: $txttwo;
  .breadcrumb-item a{color: $placeholder;}
  .breadcrumb-item.active{color: $title;}
  i{color: #000;}
}
.breadcrumb-item + .breadcrumb-item{padding-left: 0.3rem;}
.breadcrumb-item + .breadcrumb-item::before{padding-right: 0.3rem;}
nav{
  .heading-card{font-size: 19px;}
}
.btn-outline-primary{width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;border-radius: 4px;
              border:1px   solid #DFE6EE;color:$bg !important;margin-right:20px;
              &:hover{background-color: $bg;color: #fff !important;
                text-decoration: none;}
}
// button

// switch
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;top:3px;
  left: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  // box-shadow: 0px 2px 6px #B0BAC5;
}

input:checked + .slider {
  background-color: $btn;
}

input:focus + .slider {
  box-shadow: 0 0 1px $complete;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.dbtext{
    padding-right: 10px;
}
.toggleButton{
    margin-right: 50px;
}
// button
.main-container{
  .btn{
    height: 3.5rem;border-radius: 2px;
    font-size: 1.125rem;color: #fff;text-transform: uppercase;-webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    
    &:hover{text-decoration: none;}
    &:hover{box-shadow: none;}
    &.btn-submit{
        background-color: $btn;font-weight: 700;border:2px solid $btn;
        
        &:hover{background-color: transparent;color: #fff;border:2px solid #fff;text-decoration: none;}
        
    }
    &.form-btn{max-height: 46px;font-size: 0.875rem; background-color: $btn;font-weight: 500;border:1px solid $btn;
        padding: 0.625rem 3.125rem;min-width:10.5rem;margin-right:20px;border-radius:8px;
        &:hover{background-color: transparent;color: $btn;border:1px solid $btn;text-decoration: none;}
        @media screen and (max-width: 1536px) {
          max-height: 40px !important;
        }
    }
    &.btn-cancel{background-color: transparent;color: $bg;border:2px solid $btn;
        &.form-btn{max-height: 46px;font-size: 0.875rem;border:1px solid $bg;
            &:hover{background-color: $bg;font-weight: 700;border:1px solid $btn;text-decoration: none;color: #fff;}
        }
        &:hover{background-color: $btn;font-weight: 700;border:2px solid $btn;text-decoration: none;}
        @media screen and (max-width: 1536px) {
          max-height: 40px !important;
        }
    }
    &.btn-success{
        background-color: $complete;font-weight: 700;border:2px solid $complete;padding: 0.35rem 1.5rem;
        height: auto;line-height: 1rem;border-radius: 2px;font-size:0.875rem;
        &:hover{text-decoration: none;}
        // &:hover{background-color: transparent;color:$complete;border:2px solid $complete;text-decoration: none;}
        
    }
    &.btn-warning{
        background-color: $active;font-weight: 700;border:2px solid $active;padding: 0.4rem 1.5rem;
        height: auto;line-height: 1rem;border-radius: 2px;font-size:0.875rem;
        &:hover{text-decoration: none;}
        // &:hover{background-color: transparent;color:$complete;border:2px solid $complete;text-decoration: none;}
        
    }
    &.btn-danger{
        background-color: $pending;font-weight: 700;border:2px solid $pending;padding: 0.4rem 1.5rem;
        height: auto;line-height: 1rem;border-radius: 2px;font-size:0.875rem;
        &:hover{text-decoration: none;}
        // &:hover{background-color: transparent;color:$complete;border:2px solid $complete;text-decoration: none;}
        
    }
    &.btn-default{
        background-color: #F5F6FA;font-weight: 400;border:1px solid #DFE6EE;padding: 5px;width: 30px;height: 30px;
        border-radius: 4px;font-size:0.875rem;color:$bg;text-transform:capitalize;color:#3C464D;
        &:hover{background-color: $btn;color:#fff;border:1px solid $btn;text-decoration: none;}
        
    }
    &.btn-outline-danger{border:1px solid $pending;color: $pending;}
   
    
  }
}

// Ngselect
// NgSelect CSS
.ng-select.ng-select-single{
  min-width: 4rem;
  .ng-select-container{
    font-size: 1rem;
      height: 40px !important;
      border: 0.06rem solid $control-border-color;
      border-radius:4px;
      color: $bg;
      font-weight: 500;
  }
  .ng-dropdown-panel{
      .ng-dropdown-panel-items{
          .ng-option{
            font-size: 1rem;
            color: $bg;
            font-weight: 700;
          }
      }
  }
  // &.ng-select-focused{
  //     .ng-select-container{
  //         border-color: $primary;
  //         background-color: $grey-5;
  //         box-shadow: 0 0 0 0.06rem $primary;
  //     }
  // }

  
}

.btncircle{
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #002d7c !important;
  border-color: #002d7c !important;
  color: #fff;
}

.form-control:disabled, .form-control[readonly], .ng-select-control .ng-select-container:disabled, .ng-select-control .ng-select-container[readonly] {
  background-color: #f4f4f9 !important;
}
textarea{height: 120px;max-height: inherit !important;
  &.ep-code-desp{
    height: 200px !important;
  }
  &.question-name{
    height: 60px !important;
  }
  @media screen and (max-width: 1536px) {
    height: 90px;
  }
}
// Tabs
.p-tabview-nav{
  border-bottom: none !important;
  li{
    flex:1;border: 1px solid #DDDDDD;
    &.p-highlight{
      .p-tabview-nav-link{
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);border-bottom: none;
        
        background-color: $btn !important;color:#fff !important;
        
          span{
            background-color: #fff;color: $btn;
        }
        &:hover{border-bottom: none;}
        
      }
    }
    .p-tabview-nav-link{
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
        padding: 10px 20px !important;
      color: $tab !important;
      height: 60px;
      div {display: flex;align-items: center;}
    }
    

    span{
      width: 40px;
      height: 40px;
      background: #009AD9;
      border-radius: 35px;margin-right: 9px;display: inline-block;vertical-align: middle;
      font-weight: bold;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
      @media screen and (max-width: 1280px) {
        width: 30px;height:30px;line-height:30px
      }
    }
  }
}
.p-tabview-panels{padding: 1rem 0 !important;}

@media screen and (max-width: 1536px) {
  .logo{max-width: 200px !important;}
  .login-form{margin-top: 70px !important;
    .form-control{min-height: 50px !important;}
    h4{font-size: 34px !important;line-height:40px !important}
    p{margin-bottom: 20px !important;}

  }
  
  .sub-btn{max-height: 50px !important;line-height: 0 !important;margin-bottom: 30px !important;margin-top: 30px !important;}
}
@media screen and (max-width: 1366px) {
  .right-content{right: 630px !important;}
}
@media screen and (max-width: 1280px) {
  .right-content{right: 642px !important;}
}

@media screen and (max-width: 992px) {
  
  .bread-wrap{position: relative;top:0;z-index: 1;}
  ::ng-deep {
    .dataTables_wrapper {
      .dataTables_filter 
      {
        right:170px;
        label {
          input{width: 225px !important;}
        }
      }
    }
  }
  .right-content{top:56px !important;width: 38% !important;right:447px !important}
  
}

th.sorting.sorting_asc{
  background-position: left !important;
}
th.sorting.sorting_desc
{
  background-position: left !important;
}
th.sorting {
  background-position: left !important;
}
